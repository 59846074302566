// Import the new parallax manager
import { initParallax } from "./utility/parallax.js";
import { MouseTracker } from "./utility/mouse.js";
import { Helpers } from "./utility/helpers.js";
import { DOMCache } from "./utility/dom.js";
import { GradientAnimator } from "./utility/gradient.js";
import { CleanupManager } from "./utility/cleanup.js";
import { moduleLoader } from "./load.js";

// Initialize managers
const domCache = new DOMCache();
const cleanupManager = new CleanupManager();
const { delay } = Helpers;

// Track instances
let parallaxInstance = null;
let mouseTracker = null;
let gradientAnimator = null;

// Add a function to handle stage title updates
function updateStageTitle(newTitle) {
  // Add the text-changing class to fade out
  domCache.stageTitle.classList.add("text-changing");

  // Update the text after fade out
  setTimeout(() => {
    domCache.stageTitle.innerHTML = newTitle;
    // Remove the class to fade back in
    domCache.stageTitle.classList.remove("text-changing");
  }, 800);
}

// Common class reset function
function resetContainerViews() {
  // Remove view state classes
  document.body.classList.remove("signup");
  document.body.classList.remove("register");
  document.body.classList.remove("read-more");
  document.body.classList.remove("show-signup");
  document.body.classList.remove("loading");
}

// Action handlers map
const actionHandlers = {
  "show-legal": () => {
    return false; // already handled by the module
  },
  "show-ai": () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    resetContainerViews();
    document.body.classList.remove("ready");
    document.body.classList.add("show-ai");
    parallaxEffect();
  },
  "learn-more": () => {
    resetContainerViews();
    document.body.classList.add("read-more");

    // Expand container to maximum height
    const container = document.querySelector(".signup-container");
    if (container) {
      const maxHeight = (90 * window.innerHeight) / 100;
      container.style.height = `${maxHeight}px`;
    }

    // Scroll and update UI
    window.scrollTo({ top: 0, behavior: "smooth" });
    domCache.moreAbout.scrollIntoView({ behavior: "smooth" });
    domCache.learnMore.innerHTML = "(Learn Less)";
    domCache.learnMore.dataset.action = "learn-less";
  },
  "learn-less": () => {
    resetContainerViews();
    domCache.learnMore.innerHTML = "Learn More<i></i>";
    domCache.learnMore.dataset.action = "learn-more";

    // Scroll and cleanup
    domCache.aboutContainer.scrollTo({ top: 0, behavior: "smooth" });
    window.scrollTo({ top: 0, behavior: "smooth" });
    domCache.learnMore.blur();
  },
  "close-signup": () => {
    resetContainerViews();
    document.body.classList.add("hide-signup");
  },
  "open-signup": () => {
    // Update title
    if (document.body.classList.contains("signup")) {
      updateStageTitle("About Firesite.ai");
    } else {
      domCache.stageTitle.innerHTML = "About Firesite.ai";
    }

    resetContainerViews();
    document.body.classList.remove("hide-signup");
    document.body.classList.add("show-signup");

    // Update container states
    domCache.signupContainer.classList.remove("active");
    domCache.startContainer.classList.remove("active");
    domCache.aboutContainer.classList.add("active");

    // Scroll and update UI
    domCache.aboutContainer.scrollTo({ top: 0, behavior: "smooth" });
    window.scrollTo({ top: 0, behavior: "smooth" });
    domCache.learnMore.innerHTML = "Learn More<i></i>";
    domCache.learnMore.dataset.action = "learn-more";
  },
  "begin-signup": () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    resetContainerViews();

    document.body.classList.add("signup");
    document.body.classList.add("register");
    domCache.signupContainer.classList.add("active");
    domCache.startContainer.classList.remove("active");

    // Refresh DOM cache after form becomes visible
    requestAnimationFrame(async () => {
      // Reinitialize DOM cache
      domCache.initCache();

      // Initialize signup module
      const initialized = await moduleLoader.initializeModules();
      const signupModule = initialized.get("signup");
      if (signupModule) {
        window.betaSignup = signupModule; // Maintain backward compatibility
      }
    });
  },
  "start-signup": () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    updateStageTitle("Signup For BETA Access");
    resetContainerViews();

    document.body.classList.add("signup");
    domCache.startContainer.classList.add("active");
    domCache.signupContainer.classList.remove("active");
    domCache.aboutContainer.classList.remove("active");
  },
  "form-next": async (event) => {
    console.log("form-next handler called");

    if (!event || !window.betaSignup) {
      console.log("Missing event or betaSignup:", {
        event: !!event,
        betaSignup: !!window.betaSignup,
      });
      return;
    }

    event.preventDefault();
    const form = event.target.closest(".fs-form");
    if (!form) {
      console.log("No form found");
      return;
    }

    console.log("Calling handleNextStep");
    await window.betaSignup.handleNextStep();
  },
  "form-back": (event) => {
    if (!event || !window.betaSignup) return;

    event.preventDefault();
    const form = event.target.closest(".fs-form");
    if (!form || !domCache.formBackButtons.length) return;

    window.betaSignup.handlePreviousStep();
  },
};

// Global click handler at document level
document.addEventListener("click", (event) => {
  // Find the closest action element
  const actionElement = event.target.closest("[data-action]");
  if (!actionElement) return;

  // Get the action from the element
  const action = actionElement.dataset.action;
  console.log("Action clicked:", action);

  const handler = actionHandlers[action];
  console.log("Handler found:", !!handler);

  // If the action has a handler, call it with the event
  if (handler) {
    // Check if it's a form action that needs the event
    if (action.startsWith("form-")) {
      console.log("Calling form handler");
      handler(event);
    } else {
      handler();
    }
  }

  // Expand container and scroll to bottom
  if (action === "begin-signup") {
    // Expand container to maximum height
    const container = document.querySelector(".signup-container");
    if (container) {
      container.style.height = "895px";
    }

    // Scroll and update UI
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }
});

// New parallax effect implementation
function parallaxEffect() {
  // Initialize parallax
  if (parallaxInstance) {
    parallaxInstance.destroy();
  }
  parallaxInstance = initParallax();
  cleanupManager.add(() => parallaxInstance?.destroy());

  // Initialize shooting stars
  setTimeout(() => {
    initShootingStars();
  }, 1500);
}

// Update dynamic elements
function updateElementValues() {
  domCache.updateYearSpans();
}

// Initialize resize functionality
function initResizeHandler() {
  const container = document.querySelector(".signup-container");
  const handle = document.querySelector(".resize-handle");
  if (!container || !handle) return;

  let startHeight, startY;

  function onMouseDown(e) {
    startHeight = container.offsetHeight;
    startY = e.clientY;
    document.body.classList.add("resizing");

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }

  function onMouseMove(e) {
    if (!startHeight) return;

    const delta = startY - e.clientY;
    const newHeight = Math.max(
      655,
      Math.min((90 * window.innerHeight) / 100, startHeight + delta)
    );
    container.style.height = `${newHeight}px`;
  }

  function onMouseUp() {
    document.body.classList.remove("resizing");
    startHeight = null;
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
  }

  handle.addEventListener("mousedown", onMouseDown);

  // Add cleanup
  cleanupManager.add(() => {
    handle.removeEventListener("mousedown", onMouseDown);
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
  });
}

// Initialize page animations
async function initializeAnimations() {
  try {
    if (!domCache.validateCriticalElements()) return;

    // Initialize mouse tracker
    mouseTracker = new MouseTracker();
    cleanupManager.add(() => mouseTracker?.destroy());

    // Initialize gradient animator
    gradientAnimator = new GradientAnimator(domCache.interBubble, {
      easing: 20,
      useTransform3d: true,
      roundValues: true,
    });
    cleanupManager.add(() => gradientAnimator?.destroy());

    // Subscribe to mouse movement updates
    mouseTracker.subscribe((x, y) => {
      gradientAnimator.update(x, y);
    });

    // Scroll to top
    window.scrollTo(0, 0);

    // Initial page load animation
    await delay(100);
    domCache.appContainer.classList.add("loaded");
    document.body.classList.add("ready");

    // Scroll to top
    window.scrollTo(0, 0);

    // AI message animation
    await delay(500);
    if (domCache.aiMsg) {
      domCache.aiMsg.classList.add("show");
    }

    // Initialize mouse tracking and start animation
    mouseTracker.init();
    gradientAnimator.start();

    // Ensure page starts at top on load
    await delay(100);
    window.scrollTo(0, 0);

    // Initialize other functions
    updateElementValues();
    initWordRotation();
    initResizeHandler();

    // Return cleanup function
    return () => {
      cleanupManager.cleanup();
    };
  } catch (error) {
    console.error("Animation initialization error:", error);
    cleanupManager.cleanup();
  }
}

// Ensure animations are initialized after DOM is fully loaded
document.addEventListener("DOMContentLoaded", initializeAnimations);

// Word Rotation
function initWordRotation() {
  const wordRotate = document.querySelector(".word-rotate-items");
  if (!wordRotate) return;

  const words = Array.from(wordRotate.children);
  let currentIndex = 0;
  let rotationInterval;

  function rotateWords() {
    words.forEach((word) => word.classList.remove("active"));
    words[currentIndex].classList.add("active");
    currentIndex = (currentIndex + 1) % words.length;
  }

  // Initial state
  words.forEach((word, index) => {
    if (index !== 0) word.classList.remove("active");
  });

  // Create intersection observer
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start rotation when visible
          rotationInterval = setInterval(rotateWords, 2000);
          // Stop observing once started
          observer.disconnect();
        }
      });
    },
    {
      threshold: 0.5, // Start when element is 50% visible
    }
  );

  // Start observing
  observer.observe(wordRotate);

  // Add cleanup to the cleanup manager
  cleanupManager.add(() => {
    if (rotationInterval) clearInterval(rotationInterval);
    observer.disconnect();
  });
}

// Shooting Star Animation Manager
function initShootingStars() {
  const stars = document.querySelectorAll(".shooting");
  let isAnimating = false;

  function activateRandomStar() {
    if (isAnimating) return;

    const availableStars = Array.from(stars);
    const randomStar =
      availableStars[Math.floor(Math.random() * availableStars.length)];

    isAnimating = true;
    randomStar.classList.add("active");

    // Wait for animation to complete
    randomStar.addEventListener(
      "animationend",
      () => {
        randomStar.classList.remove("active");
        isAnimating = false;

        // Schedule next star after a random delay
        setTimeout(activateRandomStar, Math.random() * 8000 + 4000); // Random delay between 4-12 seconds
      },
      { once: true }
    );
  }

  // Start the cycle
  activateRandomStar();
}

// Auto-scroll functionality
function initAutoScroll() {
  let idleTimer = null;
  let isScrolling = false;
  let lastScrollPosition = window.pageYOffset;

  // Function to check if scroll is allowed
  function isScrollAllowed() {
    const bodyHasShowAI = document.body.classList.contains("show-ai");
    const bodyIsScrolled = document.body.classList.contains("scrolled");
    return bodyHasShowAI && !bodyIsScrolled;
  }

  // Function to perform the scroll
  function scrollToBottom() {
    if (isScrollAllowed() && !isScrolling) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  // Reset idle timer
  function resetIdleTimer() {
    if (idleTimer) clearTimeout(idleTimer);
    idleTimer = setTimeout(scrollToBottom, 5000); // 5 seconds of idle time
  }

  // Detect user scroll
  window.addEventListener(
    "scroll",
    () => {
      isScrolling = true;
      const currentScrollPosition = window.pageYOffset;

      // After 150ms without scrolling, assume scrolling has stopped
      clearTimeout(window.scrollTimeout);
      window.scrollTimeout = setTimeout(() => {
        isScrolling = false;
        lastScrollPosition = currentScrollPosition;
        resetIdleTimer();
      }, 150);
    },
    { passive: true }
  );

  // Reset timer on user interaction
  ["mousemove", "click", "keypress", "touchstart"].forEach((event) => {
    window.addEventListener(event, resetIdleTimer, { passive: true });
  });

  // Initial timer start
  resetIdleTimer();
}

// Set random dude image
function setRandomDudeImage() {
  const dudeElement = document.querySelector(".dude");
  if (!dudeElement) return;

  const images = [
    "../assets/img/splash-people-one.png",
    "../assets/img/splash-people-two.png",
    "../assets/img/splash-people-three.png",
    "../assets/img/splash-people-four.png",
    "../assets/img/splash-people-five.png",
  ];
  const randomImage = images[Math.floor(Math.random() * images.length)];
  dudeElement.style.backgroundImage = `url("${randomImage}")`;
}

// Initialize auto-scroll with other initializations
document.addEventListener("DOMContentLoaded", () => {
  setRandomDudeImage();
  initAutoScroll();
});
